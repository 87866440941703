/* eslint-disable @typescript-eslint/ban-ts-comment */

import { GrowthBook } from '$growthbook'

import type { CurrentUser } from '$lib/stores/auth-state'
import { once } from 'lodash-es'
import type { Readable, Subscriber } from 'svelte/store'

const growthbook = new GrowthBook()
const FEATURES_ENDPOINT = 'https://cdn.growthbook.io/api/features/key_prod_f5dbc6117c51b1cf'

function setUser(user: CurrentUser) {
  const isSignedIn = !!user.id
  growthbook.setAttributes({
    id: user.email,
    loggedIn: isSignedIn,
    employee: user.email?.endsWith('@airheart.com'),
  })
  store.notify()
}

export const loadFreatureFlags = once(loadFreatureFlagsInternal)

// type Features = Record<string, FeatureDefinition<any>>

const featureStore = () => {
  const subscribers: Set<Subscriber<Record<string, boolean>>> = new Set()

  function normalizeFeatures(): Record<string, boolean> {
    const result: Record<string, boolean> = {}
    Object.keys(growthbook.getFeatures()).forEach((key) => {
      result[key] = on(key)
    })

    return result
  }

  const subscribe = (subscriber: Subscriber<Record<string, boolean>>) => {
    subscribers.add(subscriber)
    const features = normalizeFeatures()
    subscriber(features)
    return () => subscribers.delete(subscriber)
  }

  const notify = () => {
    const features = normalizeFeatures()
    subscribers.forEach((subscriber) => subscriber(features))
  }

  return { subscribe, notify }
}

function on(name: string): boolean {
  return growthbook.isOn(name)
}

const store = featureStore()

// store.subscribe((features) => {
//   console.log('Features changed', features)
// })

async function loadFreatureFlagsInternal() {
  return fetch(FEATURES_ENDPOINT)
    .then((res) => res.json())
    .then((json) => growthbook.setFeatures(json.features))
    .then(store.notify)
    .catch(() => console.error('Cannot connect to features service'))
    .finally(() => console.log('Features loaded'))
}

const feature: Readable<Record<string, boolean>> & {
  setUser: typeof setUser
  on: typeof on
} = {
  subscribe: store.subscribe,
  on,
  setUser,
}

export default feature
